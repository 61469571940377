<template>
  <div>
    <!-- Header Alert -->
    <b-alert
      show
      variant="light"
      class="alert alert-custom alert-white alert-shadow fade show gutter-b"
    >
      <div class="alert-icon">
        <span class="svg-icon svg-icon-lg">
          <inline-svg src="/media/svg/icons/Tools/Compass.svg" />
        </span>
      </div>
      <div class="alert-text">Daftar <b>Konfigurasi Video</b></div>
      <!-- <b-button
        squared
        @click="$router.push('/pitching-video-conf-settings/add')"
        v-if="btnAccess"
        variant="primary"
        >Tambah</b-button
      > -->
    </b-alert>

    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <div>
              <!-- Filter -->
              <div class="row justify-content-end mt-n3">
                <div class="col-md-3">
                  <treeselect
                    v-model="filter.pitching_event_id"
                    :multiple="false"
                    placeholder="Saring Pitching Event"
                    :options="pitching_events"
                    @input="filterByPitchingEvent"
                  />
                </div>
              </div>
              <b-table
                striped
                hover
                :items="items"
                :fields="fields"
                class="mt-3"
                responsive
              >
                <template #table-colgroup="scope">
                  <col
                    v-for="field in scope.fields"
                    :key="field.key"
                    :style="{ width: field.key === 'action' ? '15%' : '' }"
                  />
                </template>

                <template #cell(province_name)="data">
                  <span class="text-capitalized">{{data.item.province_name.toString()}}</span>
                </template>

                <template #cell(action)="data">
                  <b-button
                    size="sm"
                    class="mr-1 btn-circle btn-outline-info"
                    v-b-tooltip.hover
                    title="Detail"
                    @click="
                      $router.push({
                        path: '/pitching-video-conf-settings/detail/' + data.item.id,
                      })
                    "
                    ><i class="fas fa-eye px-0"></i
                  ></b-button>
                  <b-button
                    size="sm"
                    class="mr-1 btn-circle btn-outline-success"
                    v-b-tooltip.hover
                    title="Edit"
                    @click="
                      $router.push({
                        path: '/pitching-video-conf-settings/edit/' + data.item.id,
                      })
                    "
                    v-if="data.item.status != 'special' && btnAccess"
                    ><i class="fas fa-edit px-0"></i
                  ></b-button>
                  <b-button
                    size="sm"
                    class="btn-circle btn-outline-danger"
                    v-b-tooltip.hover
                    title="Hapus"
                    @click="deleteData(data.item.id)"
                    v-if="data.item.status != 'special' && btnAccess"
                    ><i class="fas fa-trash px-0"></i
                  ></b-button>
                </template>
              </b-table>

              <b-pagination
                v-if="items.length != 0"
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                @page-click="pageOnClick"
                class="mt-4"
              ></b-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import module from "@/core/modules/CrudModule.js";

export default {
  data() {
    return {
      // filter
      filter: {
        name: "",
        pitching_event_id: ''
      },
      // Pagination
      perPage: 20,
      currentPage: 1,
      totalRows: 1,
      fields: [
        {
          key: "number",
          label: "No",
          sortable: true,
        },
        {
          key: "pitching_event_name",
          label: "Event Pitching",
          sortable: true,
        },
        {
          key: "video_mute_display",
          label: "Video Mute",
          sortable: true,
        },
        {
          key: "mic_mute_display",
          label: "Mic Mute",
          sortable: true,
        },
        {
          key: "sharing_screen_display",
          label: "Berbagi Layar",
          sortable: true,
        },
        {
          key: "video_resolution",
          label: "Resolusi Video",
          sortable: true,
        },
        {
          key: "action",
          label: "Aksi",
          tdClass: "nowrap"
        },
      ],
      items: [],
      pitching_events: [],
      // access
      btnAccess: true,
    };
  },
  methods: {
    pageOnClick(evt, page) {
      evt.preventDefault();
      //("evt", evt);
      //("page", page);
      this.currentPage = page;
      this.pagination();
    },

    async pagination() {
      let filterParams = `&name=${this.filter.name}`;
      let response = await module.paginate(
        "api/pitching-video-conf-settings",
        `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`
      );
      let pagination = response.meta.pagination;
      this.totalRows = pagination.total;

      let a, b;
      b = this.perPage * (this.currentPage - 1) + 1;
      for (a = 0; a < response.data.length; a++) {
        response.data[a].number = b + a;
      }

      this.items = response.data;
    },

    async getPitchingEventOption() {
      let response = await module.setTreeSelect("api/pitching-events");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.pitching_events = response.data;
        this.pitching_events.unshift({
          label: "Saring Pitching Event",
          id: '',
        });
      }
    },

    filterByName() {
      this.pagination();
    },
    filterByPitchingEvent(evt) {
      if(typeof evt != 'undefined'){
        this.filter.pitching_event_id = evt
        this.pagination()
      }else{
        this.filter.pitching_event_id = ''
        this.pagination()
      }
    },

    async deleteData(id) {
      // Delete Data
      let result = await module.delete("api/pitching-video-conf-settings/" + id);
      // If Deleted
      if (result) {
        this.pagination();
      }
    },

    // access
    checkAccess() {
      let access_right_user = window.localStorage.getItem("access_right_display")
      let access_right = JSON.parse(access_right_user)
      for (let a = 0; a < access_right.length; a++) {
        if (access_right[a] == "6006") {
          this.btnAccess = true
        }
      }
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Konfigurasi Video", route: "/pitching-video-conf-settings" }
    ]);
    this.pagination();
    this.checkAccess();
    this.getPitchingEventOption();
  },
};
</script>

<style>
.nowrap {
  white-space: nowrap;
}
</style>
